import React from 'react'
import Page from '../components/Page'

const InterculturalPage = () => (
    <Page title="INTERCULTURAL BRIDGE / TRANSFORMATION" description="INTERCULTURAL BRIDGE / TRANSFORMATION">
      <p className="mt-8 w-2/3 text-center">
        Have you ever experienced intercultural misunderstandings in oral or written communication?<br/>
        Have your arguments ever been misunderstood when directed at a culture different to yours?<br/>
        <br/>
        Our technical linguists are there to transform and adjust your arguments to the cultural language environment of the target language.<br/>
        <br/>
        We help to resolve CULTURAL DISCREPANCIES<br/>
      </p>
    </Page>
)

export default InterculturalPage